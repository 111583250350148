import React from 'react';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
//import  "tabulator-tables/dist/css/tabulator_semanticui.css";
//import  "tabulator-tables/dist/css/tabulator_bootstrap5.css";
//import  "tabulator-tables/dist/css/tabulator_bootstrap4.css";
//import  "tabulator-tables/dist/css/tabulator_midnight.css";
//import  "tabulator-tables/dist/css/tabulator_modern.css";
//import  "tabulator-tables/dist/css/tabulator_site.css";
//import  "tabulator-tables/dist/css/tabulator_materialize.css";
//import  "tabulator-tables/dist/css/tabulator_bootstrap3.css";
import  "tabulator-tables/dist/css/tabulator.css";

import './TabulatorSoftdata.css';

class TabulatorSoftdata extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: props.rowData,
            columnDefs: props.columnDefs,
            columns: props.columns,
            height: 0,
            tabulator: null,            
        };
        this.initializeState = this.initializeState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);      
        this.formatRow = this.formatRow.bind(this);
    }

    componentDidUpdate = async (prevProps) => {        
        if (this.props.rowData !== prevProps.rowData) {                     
            this.initializeState();
        }        
    }

    componentDidMount = async () => {        
        this.initializeState();
    }

    formatRow(row)
    {
        var data = row.getData();
        var element = row.getElement();
        // If element contains a keyword, change css style
        if (element.textContent.includes("Base")){
            element.css({"font-weight":"bold"});
        }    
    }

    initializeState(){
       
        var maxLineLenght=Math.max(...this.state.rowData.map(l=>l.lineName.length));
        var totalLength=maxLineLenght;
        for (let i=0;i<this.state.columns.length;i++){
            totalLength+=this.state.columns[i].title.length;
        }

        var layout = "fitColumns";

        /*if (totalLength>100){
            layout="fitData";
        }*/
        var rowFormatter = (row) => {
            if ( (row.getData().id === 'base')
                || (row.getData().id === 'media')
                || (row.getData().id === 'nps')) {
              row.getElement().style.fontWeight = 'bold';
            }
    
            return row;
        };
        var tabulatorOptions = {         
            columnHeaderVertAlign: "middle",    
            layout: layout,
            columns: this.state.columnDefs,
            data: this.state.rowData,               
            frozenRowsField: "lineName",           
            //frozenRows:["Base", "Média", "Net Promoter Score"],
            rowFormatter: rowFormatter,
        }

        var tabulatorInstance=new Tabulator('#tabulator',tabulatorOptions);
        
        this.setState({tabulator: tabulatorInstance});
        this.setState({displayTable: true});

        //tabulatorInstance.redraw(true);
        
    }

    render() {
        return (
            
            <div style={{width: "100%"}} id="tabulator"></div>
        );
    }
};
export default TabulatorSoftdata;