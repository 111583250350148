import { useContext } from 'react';

import { Link } from "react-router-dom";
import AuthContext from "./context/AuthProvider";
import { FaSignOutAlt } from "react-icons/fa";

const Logout = () => {
    const { setAuth } = useContext(AuthContext);    

    const onLinkClick = (e) => {
        
        const token="";
        setAuth({token});
        
    };

    return (
        
        <div align="center">            
            <Link to="/login" onClick={onLinkClick}>
                <FaSignOutAlt
                    title='Sair'
                    style={{ fontSize: "32px" }}                                        
                    onMouseOver={({ target }) => target.style.color = "#515860"}
                    onMouseOut={({ target }) => target.style.color = "white"}
                />
            </Link>                      
        </div>

    )
}

export default Logout;