import React from 'react'
import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const LinesChart = (props) => {
    const linechartWidth = "85%";
    const getRandomColor = (index) => {
      //const shadesOfBlue = ["#0074D9", "#7FDBFF", "#39CCCC", "#3D9970", "#2ECC40", "#01FF70", "#FFDC00", "#FF851B", "#FF4136", "#85144b", "#F012BE", "#B10DC9"];
      //const randomIndex = Math.floor(Math.random() * shadesOfBlue.length);
      //return shadesOfBlue[randomIndex];
      //const COLORS = ["#DCDCDC", "#D3D3D3", "#C0C0C0", "#A9A9A9", "#808080",
      //"#696969", "#778899", "#708090", "#2F4F4F", "#000000"];
      const COLORS = ["#c6a284", "#84c6c3", "#8487c6", "#c6c384", "#c68487", "#a8c684", "#c684a8",
			                "#84c6a2", "#c6b284", "#c68498", "#84c6b2", "#98c684", "#bec684", "#c6849d", "#5073ad"];
      //const COLORS = ["#d7e1ee", "#cbd6e4", "#bfcbdb", "#b3bfd1", "#a4a2a8", "#df8879", "#c86558", "#b04238", "#991f17"];
      //const randomIndex = Math.floor(Math.random() * COLORS.length);
      //return COLORS[randomIndex];
      const colorIndex = index%15;
      return COLORS[colorIndex];
    };

    function renderLines (linesNames) {
        
        const lines = linesNames.map((value,index) => (
          <Line
            name={value}
            type="monotone"
            dataKey={value}
            stroke={getRandomColor(index)}
            strokeWidth={3}
          />
        ));
        return lines;
    }
    const chartData = props.chartData;
    const dataKey = props.dataKey;
    //apenas as 6 primeiras linhas
    const linesNames = props.linesNames.slice(0,6);
    var YminValue=Number.MAX_SAFE_INTEGER;
    var YmaxValue=Number.MIN_SAFE_INTEGER;
    var Yvalue=0;
    var linesNamesArray = Object.keys(linesNames).map(key => linesNames[key]);
    var chartRow;
    var attr;
    for (let n=0;n<chartData.length;n++){
      for (let i=0;i<linesNamesArray.length;i++){
        chartRow = chartData[n];
        attr = linesNamesArray[i];
        Yvalue = parseFloat(chartRow[attr]);
        if (Yvalue<YminValue){
          YminValue = Yvalue;
        }
        if (Yvalue>YmaxValue){
          YmaxValue = Yvalue;
        }

      }
    }
    
    return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>    
            
            
            <LineChart 
              width={950} height={400} 
              
              data={chartData}
              margin={{ top: 30, right: 30, left: 30, bottom: 30 }}
              >
              <XAxis dataKey={dataKey} dy={5} tick={{fontSize: 16}} tickMargin={10} interval={0}/>
              <YAxis  dx={-20} tick={{fontSize: 16}} domain={[YminValue, YmaxValue]} />
              <Tooltip wrapperStyle={{fontSize: "14px"}}/>
              <Legend wrapperStyle={{fontSize: "15px", paddingLeft: "20px"}} 
                      margin={{left: 30, right: 30}}
                      layout="vertical" 
                      verticalAlign="middle" 
                      align="right"/>
              <CartesianGrid stroke="#eee" strokeDasharray="3 3"/>
              {renderLines(linesNames)}
          </LineChart>
        </div>
    );
}

export default LinesChart;