import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer } from "recharts";
import { Text } from 'recharts';

const barchartWidth = "100%";

const CustomXAxisTick = ({ x, y, payload }: any) => {
    if (payload && payload.value) {
      return (
        <Text
            fontSize={"12px"}
            width={"12px"}
            x={x} 
            y={y} 
            textAnchor="middle" 
            verticalAnchor="start"
        >{payload.value}</Text>
      );
    }
    return null;
};

class BarChartStacked extends React.Component {

    //COLORS  = ["#d7dde5","#cdd5de","#c4cdd8","#bbc4d1","#b1bcca","#a8b4c4","#9facbd",
    //           "#96a4b6","#8e9db0","#8595a9","#7c8da2","#74859c","#6b7e95","#62768f","#5a6f88"];
    COLORS = ["#c6a284", "#84c6c3", "#8487c6", "#c6c384", "#c68487", "#a8c684", "#c684a8",
               "#84c6a2", "#c6b284", "#c68498", "#84c6b2", "#98c684", "#bec684", "#c6849d", "#5073ad"];
    
    constructor(props) {
        super(props);
        this.state = {   
            quadroId: props.quadroId,   
            barData: props.barData,      
            cols: [],
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.initializeState = this.initializeState.bind(this);
        
    }


    initializeState(props){
        this.setState({quadroId: props.quadroId});
        this.setState({barData: props.barData});

        var cols = Object.keys(this.state.barData[0]);
        var colWithouName = cols.filter((item,index) => item !== "name");
        
        this.setState({cols: colWithouName});
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.bardata;
        if (this.props.quadroId !== prevProps.quadroId) {
            this.initializeState(this.props);
        }
    }

    componentDidMount = async () => {
        await this.props.bardata;
        this.initializeState(this.props);
    }

    render() {
    return (    
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            {this.state.cols.length>0 &&
                <ResponsiveContainer width={barchartWidth} height={700}>
                    <BarChart width={barchartWidth} height={700} data={this.state.barData}
                        layout="horizontal" margin={{ top: 30, right: 30, left: 50, bottom: 60 }}
                        align="center">
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis type="number" tick={{ fontSize: 14 }} />
                        <XAxis width={300} tick={<CustomXAxisTick/>}
                            tickLine={true}
                            tickMargin={5} type="category" dataKey="name" interval={0} />
                        <Tooltip shared={false}/>
                        <Legend 
                            wrapperStyle={{fontSize: "15px", paddingLeft: "20px"}} 
                            layout="vertical" verticalAlign="middle" align="right"/>
                        {this.state.cols.map((item,index) => ( 
                            <Bar dataKey={item} fill={this.COLORS[index % this.COLORS.length]} />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            }
        </div>       
    )
        }
}


export default BarChartStacked;