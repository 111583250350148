import React from 'react';

const ProcessarFiltros=(filters)=>{
    var filtersArray = [];
    if (typeof filters !== 'undefined' && filters.length > 0) {
        var jsonArray = "[";
        for (let i = 0; i < filters.length; i++) {
            jsonArray += '{"' + filters[i].replaceAll(':', '":').replaceAll(', ', ', "') + "}";
            if (i < filters.length - 1) {
                jsonArray += ", "
            }
        }
        jsonArray += "]";
        filtersArray = JSON.parse(jsonArray);            
    }
    return filtersArray;
};

export default ProcessarFiltros;
