import React from 'react';
import getReportResults from './getReportResults';
import getTextoBase from './getTextoBase';
import PieChartRecharts from '../Charts/PieChartRecharts';
import BarChartReports from '../Charts/BartChartReports';
import BarChartwRefLine from '../Charts/BarChartwRefLine';
import BarChartStacked from '../Charts/BarChartStacked';
import BarChartMulti from '../Charts/BarChartMulti';
import DatatableReports from '../Datatables/DatatableReports';
import SpinnerContent from '../common/components/SpinnerContent';

class ReportChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            studyId: props.studyId,
            studyName: props.studyName,
            quadroId: props.quadroId,
            username: props.username,
            token: props.token,
            quadros: props.quadros,
            quadrosTexto: props.quadrosTexto,
            quadro: null,
            nomeQuadro: "",
            isBarChart: false,
            isMultiBarChart: false,
            isPie: false,
            isStacked: false,
            chartLabel: "",
            tableLabel: "",
            labelTooltip: "",
            textoBase: "",
            textoBaseAdicional: "",
            textoNota: "",
            apresentaGrafico: false,
            apresentaTabela: false,
            apresentaMedia: false,
            apresentaNps: false,
            data: [],
            chartData: [],
            precisaoGrafico: 0,
            totalMediaNps: 0.0,
            drillDown: false,
            dashBoard: props.dashBoard,
            gotData: false,
            tableColumnName: "",
            tableColumTitles: [],
            tableRowName: "",
            tableRows: [],
            precisaoTabela: 0,
            valorBase: "",
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.getResults = this.getResults.bind(this);
        this.buildChartData = this.buildChartData.bind(this);
        this.buildMediaNpsChart = this.buildMediaNpsChart.bind(this);
        this.buildPieOrBarchart = this.buildPieOrBarchart.bind(this);
        this.buildStacketChart = this.buildStacketChart.bind(this);
    }

    buildMediaNpsChart() {
        var chartDataAux = [];
        var valor = 0;

        var colunas = this.state.data[0].reportResultsByColumn;
        var colunaTotal = colunas.find((col) => col.coluna === "TOTAL");

        if (this.state.apresentaMedia) {
            var totalMediaValue = parseFloat(colunaTotal.media).toFixed(1);
            this.setState({ totalMediaNps: totalMediaValue });
        } else if (this.state.apresentaNps) {
            var totalNpsValue = parseFloat(colunaTotal.nps).toFixed(0);
            if (totalNpsValue==='-0'){
                totalNpsValue=0;
            }
            this.setState({ totalMediaNps: totalNpsValue });
        }
        var colunasSemTotal = colunas.filter((col) => col.coluna !== "TOTAL");
        for (let i = 0; i < colunasSemTotal.length; i++) {
            var newRow = { name: colunasSemTotal[i].coluna };
            if (this.state.apresentaMedia) {
                valor = colunasSemTotal[i].media;
                valor = parseFloat(valor).toFixed(1);
            } else if (this.state.apresentaNps) {
                valor = colunasSemTotal[i].nps;
                valor = parseFloat(valor).toFixed(0);
                if (valor==='-0'){
                    valor=0;
                }
            }
           
            newRow[this.state.labelTooltip] = parseFloat(valor);
            chartDataAux[i] = newRow;
        }
        return chartDataAux;
    }

    buildPieOrBarchart() {
        var chartDataAux = [];
        var valor = 0;

        for (let i = 0; i < this.state.data.length; i++) {
            var newRow = { name: this.state.data[i].linha };
            var colunas = this.state.data[i].reportResultsByColumn;
            var colunaTotal = colunas.find((q) => q.coluna === "TOTAL");

            switch (this.state.quadro.gValores) {
                case 1:
                    valor = colunaTotal.pv;
                    break;
                case 3:
                    valor = colunaTotal.ph;
                    break;
                case 2:
                    valor = colunaTotal.valorBase;
                    break;
            }

            valor = parseFloat(valor).toFixed(this.state.precisaoGrafico);
            newRow[this.state.labelTooltip] = parseFloat(valor);
            chartDataAux[i] = newRow;
        }
        return chartDataAux;
    }

    buildStacketChart() {
        var chartDataAux = [];
        var valor = 0;

        for (let i = 0; i < this.state.data.length; i++) {
            var newRow = { name: this.state.data[i].linha };
            var colunas = this.state.data[i].reportResultsByColumn;
            var colunasSemTotal = colunas.filter((q) => q.coluna !== "TOTAL");
            for (let j = 0; j < colunasSemTotal.length; j++) {

                switch (this.state.quadro.gValores) {
                    case 1:
                        valor = colunasSemTotal[j].pv;
                        break;
                    case 3:
                        valor = colunasSemTotal[j].ph;
                        break;
                    case 2:
                        valor = colunasSemTotal[j].valorBase;
                        break;
                }

                valor = parseFloat(valor).toFixed(this.state.precisaoGrafico);
                var coluna = `${colunasSemTotal[j].coluna}`;
                newRow[coluna] = parseFloat(valor);
            }
            chartDataAux[i] = newRow;
        }

        return chartDataAux;
    }

    buildChartData() {
        var chartDataAux = [];
        if (this.state.apresentaMedia || this.state.apresentaNps) {
            chartDataAux = this.buildMediaNpsChart();
        } else {
            if (this.state.isStacked || this.state.isMultiBarChart) {
                chartDataAux = this.buildStacketChart();
            } else {
                chartDataAux = this.buildPieOrBarchart();
            }
        }
        this.setState({ chartData: chartDataAux });
    }

    initializeState(results) {
        if (this.state.gotData) {
            var quadro = this.state.quadros.find((q) => q.id == this.state.quadroId);
            this.setState({ quadro: quadro });
            
            var quadroTexto = this.state.quadrosTexto.find((q) => q.id == this.state.quadroId);
            this.setState({ nomeQuadro: quadroTexto.nome });

            var textoNota = quadroTexto.textoNota;
            if (typeof textoNota === 'undefined' || textoNota === null || textoNota === "") {
                this.setState({ textoNota: "" });
            } else {
                this.setState({ textoNota: quadroTexto.textoNota });
            }
            var textoBase = quadroTexto.textoBase;
            if (typeof textoBase === 'undefined' || textoBase === null || textoBase === "") {
                textoBase="";
            }

            this.setState({textoBase: textoBase+this.state.textoBaseAdicional});

            var isBarChart = quadro.gTipo === 2;
            var isMultiBarChart = quadro.gTipo === 1;
            var isPie = quadro.gTipo === 4;
            var isStacked = quadro.gTipo === 6;
            var drillDown = quadro.gLinkedChart === 1 && !this.state.dashBoard;
            this.setState({ drillDown: drillDown });
            this.setState({ isBarChart: isBarChart });
            this.setState({ isMultiBarChart: isMultiBarChart });
            this.setState({ isPie: isPie });
            this.setState({ isStacked: isStacked });

            var apresentaTabela = this.state.quadro.apresentaTabela === 1;

            var apresentaGrafico = this.state.quadro.apresentaGrafico === 1;

            if (apresentaGrafico) {
                var precisaoGrafico = this.state.quadro.precisaoGrafico;
                this.setState({ precisaoGrafico: precisaoGrafico });

                if (this.state.quadro.gApresentaMedia === 1) {
                    this.setState({ chartLabel: "Valores médios" });
                    this.setState({ labelTooltip: "Média" });
                    this.setState({ apresentaMedia: true });
                } else if (this.state.quadro.gApresentaNps === 1) {
                    this.setState({ chartLabel: "Net Promoter Score" });
                    this.setState({ apresentaNps: true });
                    this.setState({ labelTooltip: "NPS" });

                } else {
                    var gValores = this.state.quadro.gValores;
                    if (this.state.quadro.unidade===1){
                        this.setState({ chartLabel: "Valores médios" });
                        this.setState({ labelTooltip: "Média" });
                    } else {
                        switch (gValores) {
                            case 1:
                                this.setState({ chartLabel: "Percentagens verticais (%)" });
                                this.setState({ labelTooltip: "% vertical" });
                                break;
                            case 3:
                                this.setState({ chartLabel: "Percentagens horizontais (%)" });
                                this.setState({ labelTooltip: "% horizontal" });
                                break;
                            case 2:
                                this.setState({ chartLlabel: "Valores absolutos" });
                                this.setState({ labelTooltip: "v. absoluto" });
                                break;
                        }
                    }
                }
                this.buildChartData();

                this.setState({ apresentaGrafico: apresentaGrafico });
            }

            if (apresentaTabela) {
                if (this.state.quadro.gApresentaMedia === 1) {
                    this.setState({ apresentaMedia: true });
                } else if (this.state.quadro.gApresentaNps === 1) {
                    this.setState({ apresentaNps: true });
                }
                var precisaoTabela = this.state.quadro.precisao;
                this.setState({ precisaoTabela: precisaoTabela });
                this.setState({ apresentaTabela: apresentaTabela });
                if (this.state.quadro.unidade===1){
                    this.setState({tableLabel: "Valores médios"});
                } else if (this.state.quadro.tApresentaValor===1){
                    this.setState({tableLabel: "Valores absolutos"});
                } else if (this.state.quadro.tApresentaPV===1){
                    this.setState({tableLabel: "Percentagens verticais (%)"});
                } else if (this.state.quadro.tApresentaPH===1){
                    this.setState({tableLabel: "Percentagens horizontais (%)"});
                }
            }
            if (this.state.dashBoard){
                var row0 = this.state.data[0];
                var colsRow0 = row0.reportResultsByColumn;
                var colTotalRow0 = colsRow0.find((c)=>c.coluna==='TOTAL');
                var base = " ("+Intl.NumberFormat('pt-PT',{useGrouping: true,}).format(colTotalRow0.base)+")";
                this.setState({valorBase: base})
            }
        }
    }

    getResults = async (username, token, studyId, quadroId) => {
        var results = await getReportResults(username, token, quadroId);
        var textoBaseAdicional = await getTextoBase(username, token, studyId, quadroId);        
        
        if (typeof textoBaseAdicional === 'undefined' || textoBaseAdicional === null || textoBaseAdicional === "") {
            textoBaseAdicional="";
        }

        this.setState({textoBaseAdicional: textoBaseAdicional});

        this.setState({ gotData: true });
        return results;
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.quadroId !== prevProps.quadroId) {
            const results = await this.getResults(this.props.username, this.props.token, this.props.studyId, this.props.quadroId);
            this.setState({ data: results });
            this.initializeState(results);
        }
    }

    componentDidMount = async () => {
        if (typeof this.props.quadros !== 'undefined') {
            const results = await this.getResults(this.props.username, this.props.token, this.props.studyId, this.props.quadroId);
            this.setState({ data: results });
            this.initializeState(results);
        }
    }

    render() {
        return (
            <div>
                {!this.state.gotData &&
                    <SpinnerContent/>
                }                
                {this.state.gotData && this.state.apresentaGrafico && <h4>{this.state.nomeQuadro}</h4>}
                {this.state.gotData && this.state.apresentaGrafico && <hr />}
                {this.state.gotData && this.state.apresentaTabela && !this.state.dashBoard && <h6 style={{ textAlign: "right" }}><br/>{this.state.tableLabel}</h6>}
                {this.state.gotData && this.state.apresentaTabela && !this.state.dashBoard &&
                    <DatatableReports
                        studyName={this.state.studyName}
                        nomeQuadro={this.state.nomeQuadro}
                        dataTypeName={this.state.tableLabel}
                        precision={this.state.precisaoTabela}
                        data={this.state.data}
                        media={this.state.apresentaMedia}
                        nps={this.state.apresentaNps}
                        textoBase={this.state.textoBase}
                        textoNota={this.state.textoNota}
                    />}
                {this.state.gotData && this.state.apresentaGrafico && this.state.isPie &&
                    <PieChartRecharts
                        quadroId={this.state.quadroId}
                        dataTypeName={this.state.chartLabel}
                        textoNota={this.state.textoNota}
                        pieData={this.state.chartData}
                        valueKey={this.state.labelTooltip}
                        precision={this.state.precisaoGrafico}
                        drillDown={this.state.drillDown}
                        data={this.state.data}
                        chartLabel={this.state.chartLabel}
                    
                    />}
                {this.state.gotData && this.state.apresentaGrafico && this.state.isBarChart && !this.state.apresentaMedia && !this.state.apresentaNps &&
                    <BarChartReports
                        quadroId={this.state.quadroId}
                        dataTypeName={this.state.chartLabel}
                        textoNota={this.state.textoNota}
                        nomeY={this.state.labelTooltip}
                        barData={this.state.chartData}
                        drillDown={this.state.drillDown}
                        data={this.state.data}
                        chartLabel={this.state.chartLabel}
                    />
                }                
                {this.state.gotData && this.state.apresentaGrafico && this.state.isStacked &&
                    <div>
                        <h6 style={{ textAlign: "right" }}>{this.state.chartLabel}</h6>
                        <BarChartStacked
                            quadroId={this.state.quadroId}
                            dataTypeName={this.state.chartLabel}
                            textoNota={this.state.textoNota}
                            barData={this.state.chartData}                        
                        />
                    </div>
                }
                {this.state.gotData && this.state.apresentaGrafico && this.state.isMultiBarChart &&
                    <div>
                        <h6 style={{ textAlign: "right" }}>{this.state.chartLabel}</h6>
                        <BarChartMulti
                            quadroId={this.state.quadroId}
                            dataTypeName={this.state.chartLabel}
                            textoNota={this.state.textoNota}
                            barData={this.state.chartData}                        
                        />                        
                    </div>
                }
                {this.state.gotData && this.state.apresentaGrafico && (this.state.apresentaMedia || this.state.apresentaNps) &&
                    <div>
                        <h6 style={{ textAlign: "right" }}>{this.state.chartLabel}</h6>
                        <BarChartwRefLine
                            dataTypeName={this.state.chartLabel}
                            textoNota={this.state.textoNota}
                            chartData={this.state.chartData}
                            barDataKey={this.state.labelTooltip}
                            total={this.state.totalMediaNps} 
                            />                        
                    </div>
                    }
                {this.state.gotData && this.state.apresentaGrafico && this.state.dashBoard && <h6>{this.state.textoBase+this.state.valorBase}</h6>}
                {this.state.gotData && this.state.apresentaGrafico && <hr />}

            </div>
        );
    }
};
export default ReportChart;