import React from 'react';
import { Link } from "react-router-dom";
import Copyright from './Copyright';
import Version from './Version';
import './Footer.css';
import { FaFacebookSquare , FaGlobe , FaLinkedinIn , FaTwitter } from "react-icons/fa";

const Footer = () => {
    return (
            <div className='Footer'>
            <table width="100%">
                <tr>
                    <td align="left">
                        <Copyright/>
                    </td>
                    <td align="center" valign='middle' width='80%'>
                        <table>
                            <tr>
                                <td valign='middle'>
                                    <Link className='SocialIcons' to="https://www.datae.pt/" target="_blank">
                                        <FaGlobe
                                            title='Website'
                                            style={{ fontSize: "32px" }}                                        
                                            onMouseOver={({ target }) => target.style.color = "#515860"}
                                            onMouseOut={({ target }) => target.style.color = "white"}
                                        />
                                    </Link>
                                </td>
                                <td>
                                    &nbsp;&nbsp;&nbsp;
                                </td>
                                
                                <td>
                                    <Link to="https://www.linkedin.com/company/data-e" target="_blank">
                                        <FaLinkedinIn
                                            title='LinkedIn'
                                            style={{ fontSize: "32px" }}                                        
                                            onMouseOver={({ target }) => target.style.color = "#515860"}
                                            onMouseOut={({ target }) => target.style.color = "white"}
                                        />
                                    </Link>
                                </td>
                               
                                
                            </tr>
                        </table>
                    </td>
                    <td align="right">
                        <Version/>
                    </td>
                    
                </tr>


            </table>
            </div>
    );
};
export default Footer;