import React from 'react';
import axios from '../api/axios';
import {SOFTDATAWSURL} from '../config.js'

const GetFiltrosText = (filtrosArray) => {
                
    const nomesFiltros = [ ...new Set(filtrosArray.map(item => item.aggregatorName))];
    var filtrosString = "";
    for (let i=0; i<nomesFiltros.length; i++){
        filtrosString += nomesFiltros[i];
        filtrosString += " [";
        var valoresFiltros = filtrosArray.filter(item => item.aggregatorName===nomesFiltros[i]);
        for (let j=0;j<valoresFiltros.length;j++){
            filtrosString += valoresFiltros[j].aggregatorValue;
            if (j<valoresFiltros.length-1){
                filtrosString +=",";
            }

        }
        filtrosString += "]";
        if (i<(nomesFiltros.length-1)){
            filtrosString += "; ";
        }
    }

    return filtrosString;
}

export default GetFiltrosText;