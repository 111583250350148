import axiosInstance from '../api/axios';
import {SOFTDATAWSURL} from '../config.js'

const getTextoBase = async (username,token,studyId,nodeId) => {

    const response = await axiosInstance.post(SOFTDATAWSURL+"/report/gettextobase",
        {},
        {
            params: {
                username,
                token,
                studyId,
                nodeId
            }
        });

    try {
        
            const result = await response.data;        
            var resultJSON = JSON.parse(result);                    
            return resultJSON;
        

    } catch (err) {
        if (!err?.response) {
            console.log('Falha em aceder ao servidor');
        }
    }
}

export default getTextoBase;