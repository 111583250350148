import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from "recharts";
import "./recharts.css";
import { getTextWidth } from "get-text-width";

const barchartWidth = "100%";
const barchartColor = "#A9A9A9";

class BarChartSoftdata extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            barData: props.barData,
            nomeY: props.nomeY,
            handleBarClick: props.handleBarClick,
            height: 450,
            YAxisWidth: 150,
            displayChart: false,
            minX: Number.MAX_SAFE_INTEGER,
            maxX: Number.MIN_SAFE_INTEGER,
        }

        this.initializeState = this.initializeState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    initializeState() {
        var lenBarData = this.state.barData.length;
        
        var heightAux = lenBarData * 40 + 5*(lenBarData-2);
        if (heightAux<300){
            heightAux=300;
        }
        for (let i=0;i<lenBarData;i++){
            var row=this.state.barData[i];
            var value=parseFloat(row[this.state.nomeY]);
            if (value>=this.state.maxX){
                this.setState({maxX: value});
            }
            if (value<=this.state.minX){
                this.setState({minX: value});
            }
        }
        if (this.state.minX>0){
            this.setState({minX: 0});
        }
        
       
        var YAxisWidthAux = Math.max(...this.state.barData.map(l => getTextWidth(l.name)));
        this.setState({ height: heightAux });
        this.setState({ YAxisWidth: YAxisWidthAux+5 });
        this.setState({ displayChart: true });
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.barData;
        if (typeof this.props.barData !== 'undefined' && this.props.barData !== prevProps.barData) {
            this.initializeState();
        }
    }

    componentDidMount = async () => {
        await this.props.barData;
        this.initializeState();
    }

    render() {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                {this.state.displayChart &&

                    <ResponsiveContainer width={barchartWidth} height={this.state.height}>
                        <BarChart
                            width={barchartWidth}
                            height={this.state.height}
                            data={this.state.barData}
                            layout="vertical"
                            margin={{ top: 30, right: 30, left: 0, bottom: 30 }}
                            align="center"
                            onClick={this.state.handleBarClick}
                            barCategoryGap={5}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" tick={{ fontSize: 14 }} domain={[this.state.minX,this.state.maxX]}/>
                            <YAxis width={this.state.YAxisWidth} tick={{ fontSize: 14 }}
                                tickLine={true}
                                tickMargin={5} type="category" dataKey="name" interval={0} />
                            <Tooltip wrapperStyle={{fontSize: "16px"}} cursor={{fill:"#CACACA"}}/>
                            <Bar dataKey={this.state.nomeY}>
                                {this.state.barData.map((datum, entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={
                                            datum[this.state.nomeY] > 0
                                                ? '#657CB8'
                                                : '#96abda'
                                        }
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                }
            </div>
        )
    }
}

export default BarChartSoftdata;