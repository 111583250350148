import React from 'react'
import { usePromiseTracker } from "react-promise-tracker";
import SpinnerContent from './SpinnerContent';

const Spinner = (props) => {
    const { promiseInProgress } = usePromiseTracker();    
    
    return (
        promiseInProgress && (
            <SpinnerContent/>
        )
    );
};

export default Spinner;