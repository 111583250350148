//const SOFTDATAWSURL = 'http://localhost:8080/softdata/rest'

const protocol = window.location.protocol;
var wsPort = '8080';
if (protocol==='https:'){
    wsPort = '8443';
}
const hostname = window.location.hostname;
//const hostname = "10.0.1.85";
//const hostname='localhost';
const SOFTDATAWSURL = protocol+'//'+hostname+':'+wsPort+'/softdata/rest';
export {SOFTDATAWSURL}