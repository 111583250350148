import React from 'react';
import Header from './Header';
import Navigation from './Navigation';
import ReportData from './ReportData';
import Footer from '../Footer';
import Spinner from '../common/components/Spinner';


const Report = () => {
    return (
        <main className='App' style={{ color: "white", backgroundColor: "#6B747F" }}>
            <React.Fragment>
                <table width={"100%"}>
                    <tr>
                        <td><Header /></td>
                    </tr>
                    <tr>
                        <td colsSpan={2} style={{ verticalAlign: "top", paddingTop: "50px", paddingBottom: "50px" }}><Navigation /></td>
                    </tr>
                    <tr>
                        <td colSpan={2}><Footer /></td>
                    </tr>
                </table>
                <Spinner />
            </React.Fragment>
        </main>
    );
};
export default Report;