import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthContext from './context/AuthProvider';
import Tables from './Tables/Tables';
import Report from './Report/Report';
import Login from './Login';
import MainMenu from './MainMenu';
import './App.css';

function App() {
  const { auth } = useContext(AuthContext);

  if (auth && auth.token) {
    return (
             
      <Router>
        <Routes>
          <Route path="/" element={<MainMenu />} />
          <Route path="/login" element={<MainMenu />} />
          <Route path="/tables" element={<Tables />} />
          <Route path="/report" element={<Report />} />
          <Route path="*" element={<MainMenu />}/>
        </Routes>
      </Router>
     
    );
  } else {
    return (
      <Login />
    );
  }
};

export default App;
