import React from "react";
import GetFiltrosText from "./GetFiltrosText";
const Filtros = (props) => {

    const filtrosString = GetFiltrosText(props.filtrosArray);    

    return (
        <div style={{width: "900px", marginLeft:"auto",marginRight:"auto",textAlign:"left"}}>
            <span style={{fontSize: "14px"}}><strong>Filtros: </strong>
                {filtrosString}
            </span>
        </div>
    );

}

export default Filtros;