
import { Link } from "react-router-dom";
import { BsGraphUp } from "react-icons/bs";
const ReportButton = () => {

    return (
        
            <div align="center">
                <Link to="/report">
                    <button type="button"><BsGraphUp/>&nbsp;&nbsp;SoftData Report</button>
                </Link>
            </div>
        
    )
}

export default ReportButton;