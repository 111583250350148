import React from 'react'
import { BarChart, Label, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, Cell } from "recharts";
import { getTextWidth } from "get-text-width";
const barchartWidth = "100%";
const barchartColor = "#A9A9A9";

class BarChartwRefLine extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            chartData: props.chartData,
            barDataKey: props.barDataKey,
            lineValue: props.total,
            height: 450,
            YAxisWidth: 150,
            displayChart: false,
        }

        this.initializeState = this.initializeState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    initializeState() {
        var lenBarData = this.props.chartData.length;
        var heightAux = lenBarData * 40 + 5 * (lenBarData - 2);
        if (heightAux < 300) {
            heightAux = 300;
        }

        var YAxisWidthAux = Math.max(...this.state.chartData.map(l => getTextWidth(l.name)));

        this.setState({ height: heightAux });
        this.setState({ YAxisWidth: YAxisWidthAux + 5 });

        this.setState({ chartData: this.props.chartData });
        this.setState({ barDataKey: this.props.barDataKey });
        this.setState({ lineValue: this.props.total });
        this.setState({ displayChart: true });
    };

    componentDidUpdate = async (prevProps) => {
        await this.props.chartData;
        if (typeof this.props.chartData !== 'undefined' && this.props.chartData !== prevProps.chartData) {
            this.initializeState();
        }
    }

    componentDidMount = async () => {
        await this.props.chartData;
        this.initializeState();
    }
    render() {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <br />
                {this.state.displayChart &&
                    <ResponsiveContainer width={barchartWidth} height={this.state.height}>
                        <BarChart width={barchartWidth} height={this.state.height} data={this.state.chartData}
                            layout="vertical" margin={{ top: 30, right: 30, left: 0, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" tick={{ fontSize: 14 }} />
                            <YAxis width={this.state.YAxisWidth} tick={{ fontSize: 14 }}
                                tickLine={true}
                                tickMargin={5} type="category" dataKey="name" interval={0} />
                            <Tooltip wrapperStyle={{fontSize: "16px"}} cursor={{fill:"#CACACA"}}/>
                            <Bar dataKey={this.state.barDataKey}>
                                {this.state.chartData.map((datum, entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={
                                            datum[this.state.barDataKey] > 0
                                                ? '#7D93CA'
                                                : '#4D65A4'
                                        }
                                    />
                                ))}
                            </Bar>
                            <ReferenceLine x={this.state.lineValue}
                                isFront={true}
                                strokeDasharray="6 6"
                                
                                dataKey={this.state.barDataKey}
                                stroke="#afbbca"
                                strokeWidth={3}>
                                <Label style={{fontSize: "16px"}} value={" " + this.state.lineValue} position={"top"} />
                            </ReferenceLine>
                        </BarChart>
                    </ResponsiveContainer>
                }
            </div>
        )
    };
}

export default BarChartwRefLine;