import React from 'react'
import { BsLightbulb } from "react-icons/bs";
import BarChartSoftdata from './BartChartSoftdata';
import BarChartDrillDownTables from './BarChartDrillDownTables';

class BarChartTables extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chartData: props.chartData,
            chartTitle: props.chartTitle,
            columnName: props.columnName,
            barDataKey: props.barDataKey,
            aggHorizontalPercentages: props.aggHorizontalPercentages,
            totalColumns: props.totalColumns,
            selectedData: null,
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.handleBarClick = this.handleBarClick.bind(this);
        this.handleClickMainChart = this.handleClickMainChart.bind(this);
    }

    initializeState(props){
        this.setState({ chartData: props.chartData });
        this.setState({ chartTitle: props.chartTitle });
        this.setState({ columnName: props.columnName });
        this.setState({ barDataKey: props.barDataKey });
        this.setState({ aggHorizontalPercentages: props.aggHorizontalPercentages });
        this.setState({ totalColumns: props.totalColumns });
        this.setState({ selectedData: null });
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.chartData;
        if (this.props.chartData !== prevProps.chartData) {
            this.initializeState(this.props);
        }
    }

    componentDidMount = async () => {
        await this.props.chartData;
        this.initializeState(this.props);
    }

    handleBarClick = (data, index) => {    
        this.setState({ selectedData: data });        
    };

    handleClickMainChart = () => {
        this.setState({ selectedData: null });
    }

    render(){
        return (
            <div>
                {!this.state.selectedData &&
                    <div>                        
                        <BsLightbulb title="Este é um gráfico Drill-Down.
                        Clique em cada um dos items para apresentar um 2º gráfico
                        com informação adicional do item seleccionado"/>
                        <span style={{fontSize: "12px"}}>&nbsp;Nota</span>
                    </div>

                }
                {!this.state.selectedData &&
                    <div>                        
                        <BarChartSoftdata
                            barData={this.state.chartData}
                            nomeY={this.state.barDataKey}
                            handleBarClick={this.handleBarClick}
                        />
                    </div>
                }
                {this.state.selectedData &&
                    <div>
                        <BarChartDrillDownTables
                            line={this.state.selectedData.activeLabel}
                            data={this.state.aggHorizontalPercentages}
                            totalColumns={this.state.totalColumns}
                            columnName={this.state.columnName}
                            onClickEventHandler={this.handleClickMainChart}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default BarChartTables;