import React from 'react';
import ReportChart from './ReportChart';


class Dashboards extends React.Component {
    constructor(props) {
        super(props);
        
        this.state ={
            username: props.username,
            token: props.token,
            studyName: props.studyName,
            dashboards: props.dashboards,
            quadros: props.quadros,
            quadrosTexto: props.quadrosTexto,
            gotData: false,
        }

        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.initializeState = this.initializeState.bind(this);
    }    


    initializeState = async() => {                        
        this.setState({ gotData: true, });
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.nodeId!==prevProps.nodeId){            
            const dashboards = await this.props.dashboards;
            this.setState({dashboards: dashboards,});
            this.initializeState();
        }
    }

    componentDidMount = async () => {
        const dasboards = await this.props.dashboards;        
        
        this.setState({dashboards: dasboards,});
        this.initializeState();        
    }  

    render() {
        return (
            <div style={{minHeight: 639,}}>
            {this.state.gotData && 
                        
                this.state.dashboards.map((item,index) => ( 
                    index===0?
                    <div>
                        <h4>Dashboard</h4>                   
                        <br/>
                        <ReportChart 
                            studyName={this.state.studyName}
                            quadroId={item.quadroId} 
                            username={this.state.username}
                            token={this.state.token}
                            quadros={this.state.quadros}
                            quadrosTexto={this.state.quadrosTexto}
                            dashBoard={true}
                            />
                    </div>
                    :
                    <div>
                        <br/>
                        <ReportChart 
                            studyName={this.state.studyName}
                            quadroId={item.quadroId} 
                            username={this.state.username}
                            token={this.state.token}
                            quadros={this.state.quadros}
                            quadrosTexto={this.state.quadrosTexto}      
                            dashBoard={true}            
                            />                    
                    </div>
                ))            
                        
            }
           </div>
        );
    }
};
export default Dashboards;