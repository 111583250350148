import React from 'react';
import { BsZoomOut } from "react-icons/bs";
import BarChartSoftdata from './BartChartSoftdata';

const BarChartDrillDownReports = (props) => {
    //const quadroId=props.quadroId;
    const data=props.data;
    const line=props.line;
    const precision=props.precision;
    const onClickEventHandler=props.onClickEventHandler;

    const barData = [];

    const row = data.find((l) => l.linha === line);

    const columns = row.reportResultsByColumn.filter((c)=> c.coluna!=='TOTAL');
    var maxPh=0;
    for (let i=0;i<columns.length;i++){
        var newRow = {name: columns[i].coluna };
        var ph = parseFloat(columns[i].ph).toFixed(1);
        if (ph>maxPh){
            maxPh=ph;
        }
        newRow = { ...newRow,"% horizontal": ph };
        barData[i]=newRow;
    }
    
    return (
        <div>
        <h6 style={{ textAlign: "right" }}>Percentagens horizontais (%)</h6>
        <h4>{line}</h4>
        <br/>
        <BarChartSoftdata 
            handleBarClick={onClickEventHandler}
            nomeY={"% horizontal"} 
            barData={barData} 
            drillDown={false}
        />
        <center>
        <BsZoomOut 
            style={{fontSize: "32px"}} 
            onClick={onClickEventHandler} 
            title="Voltar gráfico principal"
            onMouseOver={({target})=>target.style.color="gray"}
            onMouseOut={({target})=>target.style.color="black"}
            /></center>
        </div>
    );
};

export default BarChartDrillDownReports;