import WhiteLogo from './images/WhiteLogo.js';

const MainMenuHeader = () => {
    return (
        <table>
            <tr>
                <td align="left">
                    <h1 style={{color: "white"}}>SoftData</h1>
                </td>
                <td>
                    &nbsp;
                </td>
                <td align="right">
                    <WhiteLogo height={"60%"} width={"60%"}/>
                </td>
            </tr>
        </table>
    );
};

export default MainMenuHeader;