import React from 'react'
import BarChartDrillDownReports from "./BarChartDrillDownReports";
import { BsLightbulb } from "react-icons/bs";
import BarChartSoftdata from './BartChartSoftdata';

class BarChartReports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {      
            quadroId: props.quadroId,      
            textoNota: props.textoNota,
            drillDown: props.drillDown,
            barData: props.barData,
            nomeY: props.nomeY,
            precision: props.precision,
            data: props.data,
            selectedData: null,     
            chartLabel: props.chartLabel,       
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.handleBarClick = this.handleBarClick.bind(this);
        this.handleClickMainChart = this.handleClickMainChart.bind(this);
    }

    initializeState(props){
        this.setState({quadroId: props.quadroId});
        this.setState({textoNota: props.textoNota});
        this.setState({drillDown: props.drillDown});
        this.setState({barData: props.barData});
        this.setState({nomeY: props.nomeY});
        this.setState({precision: props.precision});
        this.setState({data: props.data});        
        this.setState({selectedData: null});
        this.setState({chartLabel: props.chartLabel});
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.barData;
        if (this.props.quadroId !== prevProps.quadroId) {
            this.initializeState(this.props);
        }
    }

    componentDidMount = async () => {
        await this.props.barData;
        this.initializeState(this.props);
    }    

    handleBarClick = (data, index) => {
        if (this.state.drillDown){
            this.setState({selectedData: data});
        }
    };

    handleClickMainChart = () => {
        this.setState({selectedData: null});
    }

    render() {
        return (
            <div>
                
                {this.state.drillDown && !this.state.selectedData &&
                    <div>                        
                        <h6 style={{ textAlign: "right" }}>{this.state.chartLabel}</h6>
                        <BsLightbulb title="Este é um gráfico Drill-Down.
                        Clique em cada um dos items para apresentar um 2º gráfico
                        com informação adicional do item seleccionado"/>
                        <span style={{fontSize: "12px"}}>&nbsp;Nota</span>
                    </div>

                }
                {!this.state.selectedData &&
                    <BarChartSoftdata 
                        barData={this.state.barData}
                        nomeY={this.state.nomeY}
                        handleBarClick={this.handleBarClick}
                    />                    
                }
                {this.state.drillDown && this.state.selectedData && 
                <div>                                            
                        <BarChartDrillDownReports 
                            quadroId={this.state.quadroId} 
                            line={this.state.selectedData.activeLabel} 
                            data={this.state.data} 
                            precision={this.state.precision}
                            onClickEventHandler={this.handleClickMainChart}/>
                </div>
                }
            </div>            
        );
    }
}

export default BarChartReports;