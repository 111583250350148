import React from 'react';
import axiosInstance from '../api/axios';
import {SOFTDATAWSURL} from '../config.js'

const GetDataVaga = async (                                             
                                username,
                                token,                 
                                studyName,
                                waveName,
                                lineName,
                                columnName,
                                filters
            ) => {
                   
    const JSONselectedFilters = JSON.stringify(filters);
    const controller = new AbortController();
    const response = await axiosInstance.post(SOFTDATAWSURL+"/tables/getdatavaga",
        {},
        {
            signal: controller.signal,
            params: {
                username,
                token,
                studyName,
                waveName,
                lineName,
                columnName,
                JSONselectedFilters
            }
        });

    try {
        
            const result = await response.data;          
            controller.abort();     
            return result;
        
    } catch (err) {
        if (!err?.response) {
            console.log('Falha em aceder ao servidor');
        }
    }
}

export default GetDataVaga;