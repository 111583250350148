import { Audio } from "react-loader-spinner";
import "./Spinner.css";

const SpinnerContent = () => {
    const style = { position: "fixed", top: "23%", left: "27%", transform: "translate(-50%, -50%)" };

    return (
    <div className="spinner" style={style}>
        <Audio
            height="100"
            width="100"
            color="#3b5ba4"
            ariaLabel="audio-loading"
            wrapperStyle={{}}
            wrapperClass="wrapper-class"
            visible={true}
        />
        <h4 className="loader-message">A carregar dados</h4>
    </div>);
}

export default SpinnerContent;