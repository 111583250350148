import React from 'react';
import "tabulator-tables/dist/css/tabulator_semanticui.css";
import parse from 'html-react-parser';
import TabulatorSoftdata from './TabulatorSoftdata';
import TableToExcel from './TableToExcel';
import Filtros from '../Tables/Filtros';
import { getTextWidth } from "get-text-width";

class DatatableTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evolutivo: props.evolutivo,
            studyName: props.studyName,
            lineName: props.lineName,
            topColumn: props.topColumn,
            columnTitles: props.columnTitles,
            dataTypeName: props.dataTypeName,
            rowData: props.rowData,
            textoBase: props.textoBase,
            textoNota: props.textoNota,
            filtrosArray: props.filtrosArray,
            title2: "",
            columnDefs: [],
            columns: [],
            precision: props.precision,
            displayTable: false,
            height: 0,
            tabulator: null,
        };

        this.initializeState = this.initializeState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.setColumnDefs = this.setColumnDefs.bind(this);
    }

    setColumnDefs() {
        var columnsNoTotal = this.state.columnTitles;
        var columnArray = [];
        var rowBase = this.state.rowData.find((r)=>r.lineName==="Base");

        var columnMinWidth = 0;
        for (let i=0;i<columnsNoTotal.length;i++){
            var columnWidthLength = getTextWidth(rowBase["col" + (i + 2)]);
            if (columnWidthLength>columnMinWidth){
                columnMinWidth = columnWidthLength;
            }
        }
        
        
        var colTitlesBR = [];        
        for (let i=0;i<columnsNoTotal.length;i++){
            var column= columnsNoTotal[i];
            var prevS = ''; 
            while(prevS !== column){
                prevS = column; 
                column = column.replace(/([0-9]+) ([0-9]+)/, '$1_$2');
            }   
            var pos = 0;
            var finalColumnString="";
            var currentLineLength=0;
            var wordLength=0;
            var auxWord="";
            var colAux;
            while (column.length>0){                
                pos = column.indexOf(" ");
                if (pos < 14) {
                    var auxLength=0;
                    var auxLine="";
                    var auxLineWidth=0;
                    while ((pos!==-1) && (pos<14) && (auxLength<13)){
                        auxLine += column.substring(0,pos)+" ";
                        auxLength += pos+1;
                        auxLineWidth=getTextWidth(auxLine);
                        if (auxLineWidth>columnMinWidth){
                            columnMinWidth=auxLineWidth;
                        }
                        column = column.substring(pos+1,column.length);
                        pos = column.indexOf(" ");                                                
                    }
                    if (pos===-1||auxLength>=14){
                        auxLineWidth=getTextWidth(auxLine);
                        if (auxLineWidth>columnMinWidth){
                            columnMinWidth=auxLineWidth;
                        }                        
                        auxLineWidth=getTextWidth(column);
                        if (auxLineWidth>columnMinWidth){
                            columnMinWidth=auxLineWidth;
                        } 
                        if (auxLength>=14){
                            auxLine+="<BR/>";
                        }
                        auxLine += column;
                       
                        
                    }
                    finalColumnString+=auxLine;
                    break;                    
                } else if (pos>=14) {
                    finalColumnString += column.substring(0,pos)+"<BR/>";      
                    auxWord = finalColumnString.replaceAll("<BR/>","_");
                    wordLength = getTextWidth(auxWord.substring(0,pos));
                    if (wordLength>columnMinWidth){
                        columnMinWidth = wordLength;
                    }
                    column = column.substring(pos+1,column.length);
                }       
            }
            colTitlesBR[i] = finalColumnString.replaceAll('_','&nbsp;');
        }
        columnMinWidth = columnMinWidth*0.8;
        for (let i = 0; i < columnsNoTotal.length; i++) {            
            columnArray[i] = {
                title: colTitlesBR[i],
                field: "col" + (i + 2),
                headerSort: false,
                headerHozAlign: "center",
                hozAlign: "center",                
                headerWordWrap:true,
                formatter: "textarea",
                variableHeight:true,
                minWidth: columnMinWidth
            };
        }
        var maxLineLenght = Math.max(...this.state.rowData.map(l => getTextWidth(l.lineName)));
        maxLineLenght = maxLineLenght*0.75;
        var columnDefs = [
            { title: "", 
              field: "lineName", 
              headerSort: false,       
              minWidth: maxLineLenght,
              frozen: true }
        ];
        var colIndex=1;
        if (!this.state.evolutivo) {
            columnMinWidth = getTextWidth(rowBase["totalCol"]);
            var totalWidth=getTextWidth("Total");
            if (totalWidth>columnMinWidth){
                columnMinWidth=totalWidth;
            }
            columnDefs[colIndex] = {
                title: "Total",
                field: "totalCol", headerSort: false, headerHozAlign: "center", hozAlign: "center",
                minWidth: columnMinWidth,   
                frozen: true           
            }
        }

        if (typeof this.state.topColumn==='undefined'){
            this.setState({topColumn: ""});
        }

        if (columnArray.length > 0) {
            columnDefs[colIndex+1] = {
                title: `${this.state.topColumn}`,
                headerHozAlign: "center",
                columns: columnArray,
            }
        }

        this.setState({ columnDefs: columnDefs });
        this.setState({ columns: columnArray });
        return columnDefs;
    }

    initializeState() {

    }

    componentDidUpdate = async (prevProps) => {

        if (
            typeof this.props.rowData !== 'undefined' &&
            (this.props.rowData !== prevProps.rowData ||
                this.props.filtersArray !== prevProps.filtersArray)
        ) {
            this.setState({ displayTable: false });
            this.setState({
                evolutivo: this.props.evolutivo,
                studyName: this.props.studyName,
                lineName: this.props.lineName,
                topColumn: this.props.topColumn,
                columnTitles: this.props.columnTitles,
                dataTypeName: this.props.dataTypeName,
                rowData: this.props.rowData,
                textoBase: this.props.textoBase,
                textoNota: this.props.textoNota,
                filtrosArray: this.props.filtrosArray,
            })

            this.setState({ displayTable: true });
        }
    }

    componentDidMount = async () => {
        //this.initializeState(this.props);      
        this.setColumnDefs();
        if (!this.state.evolutivo) {
            this.setState({ title2: this.state.lineName + " por " + this.state.topColumn });
        } else {
            this.setState({ title2: this.state.lineName });
        }
        this.setState({ displayTable: true });
    }

    render() {
        return (
            <div id="tabulator-div-parent">
                {this.state.displayTable &&
                    <div>
                        <TableToExcel
                            evolutivo={this.state.evolutivo}
                            title1={this.state.studyName}
                            title2={this.state.title2}
                            dataTypeName={this.state.dataTypeName}
                            columns={this.state.columns}
                            rowData={this.state.rowData}
                            textoBase={this.state.textoBase}
                            textoNota={this.state.textoNota}
                            topColumn={this.state.topColumn}
                            filtrosArray={this.state.filtrosArray}
                        />
                    </div>
                }
                {this.state.displayTable &&
                    <div>
                        <TabulatorSoftdata
                            rowData={this.state.rowData}
                            columnDefs={this.state.columnDefs}
                            columns={this.state.columns} />
                        
                    </div>
                }

                {this.state.displayTable && this.state.textoBase !== 'undefined' && this.state.textoBase !== "" &&
                    <div style={{ width: "900px", marginLeft: "auto", marginRight: "auto", textAlign: "left" }}>
                        <span style={{ fontSize: "14px" }}><strong>Base:</strong> {parse(this.state.textoBase)}</span>
                    </div>
                }
                {this.state.displayTable && this.state.textoNota !== "undefined" && this.state.textoNota !== "" &&
                    <div style={{ width: "900px", marginLeft: "auto", marginRight: "auto", textAlign: "left" }}>
                        <span style={{ fontSize: "14px" }}><strong>Nota:</strong> {parse(this.state.textoNota)}</span>
                    </div>
                }
                {this.state.displayTable && this.state.filtrosArray.length > 0 && <div>
                    <Filtros filtrosArray={this.state.filtrosArray} />
                </div>
                }
                <br/>
                <hr/>
                <br/>
            </div>
        );
    }

}

export default DatatableTables;