import React from 'react';
import { Link } from "react-router-dom";
import Logout from '../Logout';
import '../Header.css';
import { FaHome } from "react-icons/fa";
import { BsTable, BsGraphUp } from "react-icons/bs";
import WhiteLogo from '../images/WhiteLogo.js';
const Header = () => {
    return (
        <div className='Header'>
            <table width="100%">
                <tr>
                    <td>
                        <h3><BsTable
                                            title='Tables'
                                            style={{ fontSize: "32px" }}                                                                                    
                                        />&nbsp;SoftData Tables</h3>
                    </td>
                    <td align="center" width="70%">
                        <table>
                            <tr>
                        
                                <td align="right">
                                    <Link to="/report">
                                        <BsGraphUp
                                            title='Report'
                                            style={{ fontSize: "32px" }}      
                                            onMouseOver={({ target }) => target.style.color = "#515860"}
                                            onMouseOut={({ target }) => target.style.color = "white"}                                                                              
                                        />
                                    </Link>
                                </td>
                                <td>
                                    &nbsp;&nbsp;&nbsp;
                                </td>
                                <td align="right">
                                    <Link to="/">
                                        <FaHome 
                                            title='Início'
                                            style={{ fontSize: "32px" }}                                        
                                            onMouseOver={({ target }) => target.style.color = "#515860"}
                                            onMouseOut={({ target }) => target.style.color = "white"}
                                        />
                                    </Link>
                                </td>
                                <td>
                                    &nbsp;&nbsp;&nbsp;
                                </td>
                                <td align="right">
                                    <Logout/>
                                </td>
                                </tr>
                                </table>
                    </td>
                    <td align="right">
                        <WhiteLogo height={"38px"}/>       
                    </td>
                </tr>


            </table>
        </div>
    );
};
export default Header;