import React from 'react';
import axiosInstance from '../api/axios';
import { trackPromise } from 'react-promise-tracker';
import { SOFTDATAWSURL } from '../config.js'
import Dashboards from './Dashboards';
import ReportChart from './ReportChart';
import { RiPrinterFill } from "react-icons/ri";
import ReportFirstChild from './ReportFirstChild';
import SpinnerContent from '../common/components/SpinnerContent';

class ReportData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: props.username,
            token: props.token,
            studyId: props.studyId,
            studyName: props.studyName,
            quadro: props.quadro,
            quadros: props.quadros,
            quadrosTexto: props.quadrosTexto,
            selectedNode: props.selectedNode,
            selectedNodeLabel: props.selectedNodeLabel,
            parentNode: props.parentNode,
            htmlContent: "",
            hasHTML: false,
            dashboards: [],
            dashboardTitle: "",
            isDashboard: false,
            isQuadroNormal: false,
            blankOutput: false,
            gotData: false,
        };

        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.createDashboards = this.createDashboards.bind(this);
        this.createBarModels = this.createBarModels.bind(this);
        this.renderHtmlContents = this.renderHtmlContents.bind(this);
        this.getReportDashBoards = this.getReportDashBoards.bind(this);
        this.getQuadroDashBoard = this.getQuadroDashBoard.bind(this);
        this.buildChart = this.buildChart.bind(this);
        this.buildStackedColChart = this.buildStackedColChart.bind(this);
        this.buildPieChart = this.buildPieChart.bind(this);
        this.printHTML = this.printHTML.bind(this);
        this.sanitize = this.sanitize.bind(this);
    }

    printHTML() {
        /*var divContents = document.getElementById("printableDiv").innerHTML;
        var a = window.open('', '', 'height=740, width=1280');
        a.document.write('<html>');
        a.document.write('<body>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();*/
        document.getElementById("printableIframe").contentWindow.print();
    }

    getReportDashBoards = async (nodeId) => {
        const username = this.state.username;
        const token = this.state.token;
        const response = await axiosInstance.post(SOFTDATAWSURL + "/report/getdashboards",
            {},
            {
                params: {
                    username,
                    token,
                    nodeId
                }
            });

        try {
            
                const result = await response.data;
                var dashBoards = JSON.parse(result);
                return dashBoards;
            
        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    createDashboards() {
        var dashboardsList;
        if (this.state.selectedNode !== 0) {
            dashboardsList = trackPromise(this.getReportDashBoards(this.state.selectedNode));
        } else {
            dashboardsList = trackPromise(this.getReportDashBoards(this.state.parentNode));
        }
        this.setState({ dashboards: dashboardsList });
        this.setState({ dashBoardTitle: "Dashboard - " + this.state.selectedNodeLabel });
        this.setState({ isDashboard: true });
        this.setState({ isQuadroNormal: false });

    }

    getQuadroDashBoard() {
        const quadroDashboard = this.state.quadros.find((q) => q.id == this.state.quadro.dashboardDashId);
        return quadroDashboard;
    }

    buildChart() {

    }

    buildStackedColChart() {

    }

    buildPieChart() {

    }

    createBarModels() {
        var quadro = this.state.quadro;
        var quadroDashboard = this.getQuadroDashBoard();
        var tipoQuadroDashboard = -1;
        if (typeof quadroDashboard !== 'undefined') {
            tipoQuadroDashboard = quadroDashboard.gTipo;
        }
        var apresentaDashboard = quadro.apresentaDashboard;
        var tipoQuadro = quadro.gTipo;
        var tipoDadosQuadro = quadro.tipoDados;

        if ((apresentaDashboard === 0 && tipoQuadro === 2) || (apresentaDashboard === 1 && tipoQuadroDashboard === 2)) {
            this.buildChart();
        } else if ((tipoQuadro === 1 && tipoDadosQuadro === 3) ||
            (apresentaDashboard === 0 && tipoQuadro === 6) ||
            (apresentaDashboard === 1 && tipoQuadroDashboard === 6)) {
            this.buildStackedColChart();
        } else if ((apresentaDashboard === 0 && tipoQuadro === 4) ||
            (apresentaDashboard === 1 && tipoQuadroDashboard === 4)) {
            this.buildPieChart();
        }
    }

    renderHtmlContents = async () => {
        var username = this.state.username;
        var token = this.state.token;
        var studyId = this.state.studyId;
        var nodeId = this.state.selectedNode;
        this.setState({ hasHTML: false });

        const response = await axiosInstance.post(SOFTDATAWSURL + "/report/gethtmlcontents",
            {},
            {
                params: {
                    username,
                    token,
                    studyId,
                    nodeId
                }
            });

        try {
           
                var result = await response.data;
                this.setState({ htmlContent: result });
                this.setState({ hasHTML: true });            
            
        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }

    }

    getReportStudyData() {

    }

    buildTableColumnTitles() {

    }

    initializeState = async () => {
        this.setState(
            {
                studyId: this.props.studyId,
                studyName: this.props.studyName,
                selectedNode: this.props.selectedNode,
                selectedNodeLabel: this.props.selectedNodeLabel,
                quadro: this.props.quadro,
                quadros: this.props.quadros,
                quadrosTexto: this.props.quadrosTexto,
                parentNode: this.props.parentNode,
            }
        );
        this.setState({ gotData: false });
        var tipoDados = this.state.quadro.tipoDados;
        if (tipoDados === 9) {
            this.createDashboards();
            this.setState({ blankOutput: false })
        } else if (tipoDados === 1 || tipoDados === 2 || tipoDados === 3 || tipoDados === 4 || tipoDados === 5) {
            //this.createBarModels();
            this.setState({ blankOutput: false });
            this.setState({ isDashboard: false });
            this.setState({ isQuadroNormal: true });

        } else if (tipoDados === 100) {
            if (this.selectedNode === 0) {
                this.setState({ selectedNode: this.state.parentNode })
            }
            await this.renderHtmlContents();
            this.setState({ blankOutput: false });
            this.setState({ isDashboard: false });
        } else {
            this.setState({ blankOutput: true });
        }
        const quadroTexto = this.state.quadrosTexto.find((qt) => qt.id == this.state.selectedNode);
        this.setState({ contentTitle: quadroTexto.nome });
        this.setState({ gotData: true });
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.quadro;
        
        if (typeof this.props.quadro !== 'undefined' && this.props.quadro !== prevProps.quadro) {

            this.setState({ gotData: false, blankOutput: false, hasHTML: false, selectedNode: this.props.selectedNode, isDashboard: false, isQuadroNormal: false, quadro: this.props.quadro, htmlContent: "", });
            this.initializeState();
        }
    }

    componentDidMount = async () => {
        await this.props.quadro;
        this.setState({ gotData: false, blankOutput: false, hasHTML: false, selectedNode: this.props.selectedNode, isDashboard: false, isQuadroNormal: false, quadro: this.props.quadro, htmlContent: "", });
        this.initializeState();
    }
    sanitize(html) {
        var doc = document.createElement('div');
        doc.innerHTML = html;
        return doc.innerHTML;
    }

    render() {
        return (

            <div style={{ backgroundColor: '#fff', color: '#000', padding: 20, width: "100%", minHeight: 649 }}>
                <div className="tabs-container"
                    style={{ width: "900px", marginLeft: "auto", marginRight: "auto", textAlign: "left" }}>
                    {!this.state.gotData &&

                        <SpinnerContent/>

                    }
                    {this.state.gotData && this.state.blankOutput &&
                        <ReportFirstChild 
                            studyId={this.state.studyId}
                            studyName={this.state.studyName}
                            quadroId={this.state.selectedNode}
                            username={this.state.username}
                            token={this.state.token}
                            quadros={this.state.quadros}
                            quadrosTexto={this.state.quadrosTexto}
                        />
                    }
                    {this.state.hasHTML &&
                        <div>
                            <div width="100%" style={{ minHeight: 600, }}
                                key={new Date().getTime()}
                                >                            
                                <iframe 
                                    id="printableIframe"
                                    name="printableIframe"
                                    width="950"
                                    height="600"
                                    frameBorder={0}
                                    srcDoc={this.state.htmlContent}/>
                                </div>
                            <center>
                                <br />
                                <RiPrinterFill
                                    onClick={this.printHTML}
                                    style={{ fontSize: "32px" }}
                                    title="Imprimir"
                                    onMouseOver={({ target }) => target.style.color = "gray"}
                                    onMouseOut={({ target }) => target.style.color = "black"}
                                />
                            </center>
                        </div>
                    }

                    {this.state.isQuadroNormal &&
                        <div>
                            <ReportChart
                                studyId={this.state.studyId}
                                studyName={this.state.studyName}
                                quadroId={this.state.selectedNode}
                                username={this.state.username}
                                token={this.state.token}
                                quadros={this.state.quadros}
                                quadrosTexto={this.state.quadrosTexto}
                            />
                        </div>
                    }
                    {this.state.isDashboard &&
                        <div>
                            <h2>{this.state.contentTitle}</h2>

                            <Dashboards
                                studyId={this.state.studyId}
                                studyName={this.state.studyName}
                                nodeId={this.state.selectedNode}
                                dashboards={this.state.dashboards}
                                quadros={this.state.quadros}
                                quadrosTexto={this.state.quadrosTexto}
                                username={this.state.username}
                                token={this.state.token}
                            />
                        </div>

                    }
                </div>
            </div>

        );
    }
};
export default ReportData;