import React, { useContext } from 'react';
import TablesButton from './Tables/TablesButton';
import ReportButton from './Report/ReportButton';
import Logout from './Logout';
import AuthContext from "./context/AuthProvider";
import Copyright from './Copyright';
import BackgroundImage from './images/DATAEPattern.png';
import MainMenuHeader from './MainMenuHeader';

const MainMenu = () => {
    
    const { auth } = useContext(AuthContext);
    const name = auth.name;
    const isTablesUser = auth.tables;
    const isReportUser = auth.report;
    return (
        <>
            <main className='App' style={{ backgroundImage:`url(${BackgroundImage})` }}>
            <section>
                <MainMenuHeader/>
                <br />
                <h4>Bem Vindo(a) {name}</h4>
                <br />                
                {isReportUser && <ReportButton />}
                {isTablesUser && <TablesButton />}
                <br />
                <br />
                <Logout />
                <br />
                <Copyright/>
            </section>
            </main>
        </>
    )
}

export default MainMenu