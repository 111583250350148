import React from 'react';
import ReportData from './ReportData';

const ReportFirstChild = (props) => {
    const studyId = props.studyId;
    const studyName  = props.studyName;
    const quadroId = props.quadroId;
    const username = props.username;
    const token = props.token;
    const quadros = props.quadros;
    const quadrosTexto = props.quadrosTexto;

    //Get first child node
    const quadroPai = quadros.find((q) => q.id == quadroId);
    const quadrosFilhos = quadros.filter((q) => q.quadroPai == quadroPai.id && q.estado===1);

    const quadro = quadrosFilhos[0];
    const newQuadroId = quadro.id;
    const quadroTexto = quadrosTexto.find((q)=>q.id==newQuadroId);
    const nomeQuadro = quadroTexto.nome;
    
    return (
        <ReportData 
                            username={username}
                            token={token}
                            studyId={studyId}
                            studyName={studyName}
                            selectedNode={newQuadroId} 
                            selectedNodeLabel={nomeQuadro}
                            quadro={quadro} 
                            quadros={quadros} 
                            quadrosTexto={quadrosTexto} 
                            parentNode={quadroPai}
                        />
    );
}

export default ReportFirstChild;
