import React from "react";
import { ListGroup } from "react-bootstrap";
import { FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { BsPieChartFill, BsPieChart } from "react-icons/bs";
import { BiFileBlank } from "react-icons/bi";

// renders the icon for container nodes
const ToggleIcon = ({ on, nodetype }) => {
  switch (nodetype) {
    case "html": case "report": case "blank":
      return (
        <>
          {on ? (
            <FaRegFolderOpen style={{ fontSize: "16px" }} className="icon" />
          ) : (
            <FaRegFolder style={{ fontSize: "16px" }} className="icon" />
          )}
        </>
      );    
    case "dashboard" :
        return (
            <>
            {on ? (
                <BsPieChartFill title="Dashboard" style={{ fontSize: "16px" }} className="icon" />
            ) : (
                <BsPieChart title="Dashboard" style={{ fontSize: "16px" }} className="icon" />
            )}
            </>
        );
    default:
      return null;
  }
};

// renders the icon for board nodes
const BoardIcon = ({ nodetype }) => {
  switch (nodetype) {
    case "blank":
      return <BiFileBlank style={{ fontSize: "16px" }} className="icon" />;
    case "html":
      return <HiDocumentText title="Documento"style={{ fontSize: "16px" }} className="icon" />;
    case "dashboard":
      return <BsPieChartFill title="Dashboard" style={{ fontSize: "16px" }} className="icon" />;
    case "report":
        return <FaChartBar title="Report" style={{ fontSize: "16px" }} className="icon" />;
    default:
      return null;
  }
};

const LEVEL_INDENT = 25;

// renders the individual tree item
const ReportTreeItem = ({
  level = 0,
  hasNodes,
  parent,
  isOpen,
  label,
  searchTerm,
  openNodes,
  toggleNode,
  matchSearch,
  focused,
  ...props
}) => (
  <ListGroup.Item
    {...props}
    variant="light"
    className="tree-item"
    style={{
      color: "black",
      marginLeft: searchTerm ? 0 : level * LEVEL_INDENT, // align fully left if search is happening, otherwise, indent according to level
      boxShadow: focused ? "0 0 0 .2rem rgba(49,132,253,.4)" : "none"
    }}
  >
    {(hasNodes) && (
      <div
        style={{cursor: "pointer"}}
        className="d-inline-flex"
        onClick={(e) => {
          hasNodes && toggleNode && toggleNode();
          //e.stopPropagation();
        }}
      >
        
        <ToggleIcon on={isOpen} nodetype={props.nodeType} />
        <span style={{fontSize: "14px"}}><strong>&nbsp;{label}</strong>&nbsp;</span>
        
      </div>
    )}
    
    {(!hasNodes &&  parent!=='') && 
    <div style={{cursor: "pointer"}}><BoardIcon nodetype={props.nodeType} /><span style={{fontSize: "14px"}}>&nbsp;{label}</span></div> }
    {(!hasNodes &&  parent==='') && 
    <div style={{cursor: "pointer"}}><BoardIcon nodetype={props.nodeType} /><span style={{fontSize: "14px"}}>&nbsp;<strong>{label}</strong></span></div> }
        
  </ListGroup.Item>
);

export default ReportTreeItem;