import React from 'react';
import axiosInstance from '../api/axios';
import { SOFTDATAWSURL } from '../config.js';

const GetDataEvolutivo = async (
    username,
    token,
    studyName,
    waveName,
    lineName,
    selectedVagasEvolutivo,
    filters
) => {


    const JSONVagasEvolutivo = JSON.stringify(selectedVagasEvolutivo);
    const JSONselectedFilters = JSON.stringify(filters);
    const controller = new AbortController();
    const response = await axiosInstance.post(SOFTDATAWSURL + "/tables/getdataevolutivo",
        {},
        {   signal: controller.signal,
            params: {
                username,
                token,
                studyName,
                waveName,
                lineName,
                JSONVagasEvolutivo,
                JSONselectedFilters
            }
        });

    try {
        
            const result = await response.data;
            controller.abort();
            return result;        
        
    } catch (err) {
        if (!err?.response) {
            console.log('Falha em aceder ao servidor');
        }
    }
}
export default GetDataEvolutivo;