import React from 'react';
import { BsZoomOut } from "react-icons/bs";
import BarChartSoftdata from './BartChartSoftdata';

class BarChartDrillDownTables extends React.Component {

    constructor(props){

        super(props);

        this.state = {
            lineName: props.line,
            columnName: props.columnName,
            data: props.data,
            totalColumns: props.totalColumns,
            onClickEventHandler: props.onClickEventHandler,
            barData: [],
        }

        this.initializeState = this.initializeState.bind(this);       
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    initializeState(props){
        this.setState({
            lineName: props.line,
            data: props.data,
            totalColumns: props.totalColumns,
            onClickEventHandler: props.onClickEventHandler,
            barData: [],
        });
        var cols = this.state.data.filter((l)=>l.nameLine===this.state.lineName);

        var dataAux = [];
        for (let i = 0; i < cols.length; i++) {
            
            var newRow = { name: cols[i].nameColumn };
            var vh = cols[i].resultValue;
            var vhFloat = parseFloat(vh).toFixed(1);

            newRow = { ...newRow, "% horizontal": vhFloat };
            dataAux[i] = newRow;            
               
        }
        this.setState({barData: dataAux});
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.data;
        if (typeof this.props.data!=='undefined' && this.props.data!==prevProps.data){
            this.initializeState(this.props);
        }
    }
  
    componentDidMount = async () =>{
        await this.props.data;
        this.initializeState(this.props);        
    }     

    render(){
    return (
        <div>
        { this.state.barData.length>0 &&
        <div>
            <h4>{this.state.lineName} ({this.state.columnName})</h4>
            <br/>
            <BarChartSoftdata 
                nomeY="% horizontal"
                barData={this.state.barData}   
                handleBarClick={this.state.onClickEventHandler}
            />
            <center>
            <BsZoomOut 
                style={{fontSize: "32px"}} 
                onClick={this.state.onClickEventHandler} 
                title="Voltar gráfico principal"
                onMouseOver={({target})=>target.style.color="gray"}
                onMouseOut={({target})=>target.style.color="black"}
                /></center>
        </div>
        }
        </div>
    );
    }
};

export default BarChartDrillDownTables;