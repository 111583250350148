import { Link } from "react-router-dom";
import { BsTable } from "react-icons/bs";

const TablesButton = () => {

    return (

        <div align="center">            
            <Link to="/tables">
                <button width="80%" type="button"><BsTable/>&nbsp;&nbsp;SoftData Tables</button>
            </Link>            
        </div>

    )
}

export default TablesButton;