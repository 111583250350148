import React, { useContext, useState, useEffect } from 'react';
import Select from 'react-select';
import './Navigation.css';
import axiosInstance from '../api/axios';
import AuthContext from "../context/AuthProvider";
import { trackPromise } from 'react-promise-tracker';

import ReportData from './ReportData';
import ReportNoData from './ReportNoData';
import ReportTree from './ReportTree';
import {SOFTDATAWSURL} from '../config.js'

const colourStyles = {
    control: base => ({
        ...base,
        fontSize: '14px'
    }),
    container: base => ({
        ...base,
        flex: 1
    }),
    menu: base => ({
        ...base,
        fontSize: '14px'
    }),
    placeholder: (styles) => {
        return {
            ...styles,
            fontSize: '14px',
        }
    },
    groupHeading: (styles) => {
        return {
            ...styles,
            fontSize: '16px',
            fontWeight: 'bold',
        }
    },

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,
            fontSize: '14px',
            backgroundColor: isFocused ? "#CACACA" : null,
            color: "#333333",
            width: "auto"
        };
    }
};
const options = [];
const Navigation = () => {
    const authContext = useContext(AuthContext);
    const { auth } = useContext(AuthContext);
    const username = auth.user;
    const token = auth.token;
    const [studiesLoaded, setStudiesLoaded] = useState(false);
    const studiesDefaultOption = { label: "Selecione o estudo", value: "" }
    const [selectedStudy, setSelectedStudy] = useState(studiesDefaultOption);
    const [studyName,setStudyName] = useState("");
    const [studyId,setStudyId] = useState('');
    const [quadros, setQuadros] = useState([]);
    const [quadrosTexto, setQuadrosTexto] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [hasTree,setHasTree] = useState(false);
    const [hasData, setHasData] = useState(false);
    const [selectedNode, setSelectedNode] = useState('');
    const [selectedNodeLabel, setSelectedNodeLabel] = useState('');
    const [selectedQuadro, setSelectedQuadro] = useState(null);
    const [parentNode,setParentNode] = useState('');    

    useEffect(()=>{        
        trackPromise(getStudies());        
        setStudiesLoaded(true);
    }, []);

    const getStudies = async () => {
        const response = await axiosInstance.post(SOFTDATAWSURL+"/report/getstudies",
            {},
            {
                params: {
                    username,
                    token
                }
            }
        );
        try {
           
                const result = await response.data;
                const studiesGroups = JSON.parse(result);
                
                for (let i = 0; i < studiesGroups.length; i++) {
                    var optionsGroup = [];
                    var waveItems = studiesGroups[i].waveItems;
                    for (let n = 0; n < waveItems.length; n++) {
                        optionsGroup[n] = { value: waveItems[n].id, label: waveItems[n].name };
                    }
                    options[i] = { label: studiesGroups[i].name, options: optionsGroup };
                }
                setStudiesLoaded(true);    
                              

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    const getQuadros = async (studyId) => {

        const response = await axiosInstance.post(SOFTDATAWSURL+"/report/getquadros",
            {},
            {
                params: {
                    username,
                    token,
                    studyId
                }
            });

        try {
            
                const result = await response.data;
                var quadrosJSON = JSON.parse(result);
                setQuadros(quadrosJSON);
                return quadrosJSON;
            
        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    const getQuadrosTexto = async (studyId) => {

        const response = await axiosInstance.post(SOFTDATAWSURL+"/report/getquadrostexto",
            {},
            {
                params: {
                    username,
                    token,
                    studyId
                }
            });

        try {
           
                const result = await response.data;
                var quadrosTextoJSON = JSON.parse(result);
                setQuadrosTexto(quadrosTextoJSON);
                return quadrosTextoJSON;
            

        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }

    const getTree = async (studyId) => {

        const response = await axiosInstance.post(SOFTDATAWSURL+"/report/gettree",
            {},
            {
                params: {
                    username,
                    token,
                    studyId
                }
            });

        try {
            
                const result = await response.data;
                setTreeData([]);
                var treeJSON = JSON.parse(result);            
                setTreeData(treeJSON);
                if (treeJSON.length>0){
                    setHasTree(true);                         
                }
                return treeJSON;
            
        } catch (err) {
            if (!err?.response) {
                console.log('Falha em aceder ao servidor');
            }
        }
    }    

    const handleStudyChange = (selectedStudy) => {
        setSelectedStudy(selectedStudy);
        setStudyId(selectedStudy.value);
        setStudyName(selectedStudy.label);
        setQuadros([]);
        setQuadrosTexto([]);
        setTreeData([]);
        const promises = [];
        promises.push(getQuadros(selectedStudy.value));
        promises.push(getQuadrosTexto(selectedStudy.value));
        promises.push(getTree(selectedStudy.value));
        Promise.all(promises)
            .then((result)=>{
                var q = result[0];
                var qt = result[1];
                var t = result[2];
                var firstNode = t[0];
                setSelectedNode(firstNode.key);
                setSelectedNodeLabel(firstNode.label);
                const quadroAux = q.find((q)=>q.id==firstNode.key); 
                setSelectedQuadro(quadroAux);
                setHasData(true);
            })
            .catch((error)=>{
                console.log(error);
            });        
        
    };    

    const getQuadro = (quadroId) => {
        const quadro = quadros.find((q)=>q.id==quadroId);        
        return quadro;
    }

    const handleTreeClick = (node) => {
        setHasData(false);
        var nodesIdsArray = (node.key).split('/');
        var len = nodesIdsArray.length;
        var nodeId = nodesIdsArray[len-1];
        var nodeLabel = node.label;
        var nodeParentId= node.parent;
        setSelectedNode(nodeId);
        setSelectedNodeLabel(nodeLabel);
        var quadro = getQuadro(nodeId);
        setSelectedQuadro(quadro);        
        setParentNode(nodeParentId);
        setHasData(true);        
    }

    
    return (
        <div style={{ padding: 10, color: "white", backgroundColor: "#6B747F" }}>
            <table style={{width: "100%"}}>
                <tr>
                    <td style={{ width: "20%", verticalAlign: "top", paddingTop: "2px", paddingBottom: "2px" }}>        
                        <nav style={{ verticalAlign: "top" }}>
                            <div  style={{ padding: 5}}>
                                <ul>

                                    <h5>Estudo</h5>
                                    <Select options={options}
                                        value={selectedStudy}
                                        styles={colourStyles}
                                        isClearable={false}
                                        onChange={handleStudyChange} />
                                </ul>
                            </div>
                        </nav>                                        

                        {hasTree &&
                            <ReportTree treeData={treeData} handler={handleTreeClick}/>
                        }
                    </td>
                    <td colspan={2} style={{ width: "100%", verticalAlign: "top", paddingTop: "2px", paddingBottom: "2px" }}>
                       {hasData && <div>
                        <ReportData 
                            username={username}
                            token={token}
                            studyId={studyId}
                            studyName={studyName}
                            selectedNode={selectedNode} 
                            selectedNodeLabel={selectedNodeLabel}
                            quadro={selectedQuadro} 
                            quadros={quadros} 
                            quadrosTexto={quadrosTexto} 
                            parentNode={parentNode}
                        />
                       </div>}
                       {!hasData && <div>
                            <ReportNoData title1="" title2="Benvindo ao SoftData Report." title3="Por favor selecione o estudo no canto superior esquerdo."
                                />
                        </div>}

                    </td>
                </tr>
            </table>
        </div>

    );
};
export default Navigation;