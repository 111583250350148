import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import BarChartDrillDownReports from "./BarChartDrillDownReports";
import { BsLightbulb } from "react-icons/bs";
import parse from 'html-react-parser';

class PieChartRecharts extends React.Component {
    
    /*COLORS  = ["#d7dde5","#c4cdd8","#b1bcca","#9facbd",
               "#8e9db0","#7c8da2","#6b7e95","#5a6f88"];*/
    
               
    COLORS = ["#c6a284", "#84c6c3", "#8487c6", "#c6c384", "#c68487", "#a8c684", "#c684a8",
               "#84c6a2", "#c6b284", "#c68498", "#84c6b2", "#98c684", "#bec684", "#c6849d", "#5073ad"];
    constructor(props) {
        super(props);   
        this.state ={         
            quadroId: props.quadroId,
            textoNota: props.textoNota,
            drillDown: props.drillDown,
            valueKey: props.valueKey,             
            pieData: props.pieData,
            precision: props.precision,            
            data: props.data,
            selectedData: null,
            chartLabel: props.chartLabel,
        }
        this.componentDidMount=this.componentDidMount.bind(this);
        this.componentDidUpdate=this.componentDidUpdate.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.CustomTooltip = this.CustomTooltip.bind(this);
        this.handleSliceClick  = this.handleSliceClick.bind(this);
        this.handleClickMainChart = this.handleClickMainChart.bind(this);
    }

    initializeState(props){
        this.setState({quadroId: props.quadroId});
        this.setState({drillDown: props.drillDown});
        this.setState({valueKey: props.valueKey});
        this.setState({pieData: props.pieData});
        this.setState({precision: props.precision});
        this.setState({data: props.data});
        this.setState({chartLabel: props.chartLabel});
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.data;
        if (this.props.quadroId !== prevProps.quadroId) {            
            this.initializeState(this.props);
        }
    }

    componentDidMount = async () => {    
        await this.props.data;    
        this.initializeState(this.props);
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'gray', padding: '5px', border: '1px solid #cccc' }}>
                    <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
                </div>
            );
        }

        return null;
    };
    RADIAN = Math.PI / 180;
    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
        const y = cy + radius * Math.sin(-midAngle * this.RADIAN);
      
        return (
          <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{fontSize: "16px"}}>
            {`${(percent * 100).toFixed(this.state.precision)}%`}
          </text>
        );
      };

    handleSliceClick = (data, index) => {
        if (this.state.drillDown){
            this.setState({selectedData: data});
        }        
    };

    handleClickMainChart = () => {
        this.setState({selectedData: null});
    }

    render() {
        return (            
            <div>
                {this.state.drillDown && !this.state.selectedData &&
                    <div>
                        <h6 style={{ textAlign: "right" }}>{this.state.chartLabel}</h6>
                        <BsLightbulb title="Este é um gráfico Drill-Down.
                        Clique em cada um dos items para apresentar um 2º gráfico com informação adicional do item seleccionado"/>
                        <span style={{fontSize: "12px"}}>&nbsp;Nota{this.state.textoNota!==""?" "+parse(this.state.textoNota):""}</span>
                    </div>

                }
                {!this.state.selectedData &&
                <ResponsiveContainer width='100%' height={400}>
                    <PieChart width={600} height={400}>
                        <Pie data={this.state.pieData} color="#000000" dataKey={this.state.valueKey} nameKey="name" cx="50%" cy="50%" outerRadius={170} fill="#8884d8" 
                        labelLine={false}
                        label={this.renderCustomizedLabel} onClick={this.handleSliceClick}>
                            {
                                this.state.pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                            }
                        </Pie>
                        <Tooltip content={<this.CustomTooltip />} />
                        <Legend layout="horizontal" verticalAlign="bottom" align="center"/>                    
                    </PieChart>
                </ResponsiveContainer>
                }
                {this.state.drillDown && this.state.selectedData && 
                <div>                    
                    <BarChartDrillDownReports 
                        quadroId={this.state.quadroId} 
                        line={this.state.selectedData.name} 
                        data={this.state.data} 
                        precision={this.state.precision}
                        onClickEventHandler={this.handleClickMainChart}/>
                </div>
                }
            </div>
        );
    }
}
export default PieChartRecharts;