import React from 'react';
import {useNavigate} from "react-router-dom"
import axiosInstance from './axios';

const setupAxiosInterceptors = (authContext) => {
    axiosInstance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (typeof error.response !== 'undefined'){
            if (typeof error.response.status !== 'undefined'){
                if (error.response.status === 401) {
                    const { setAuth } = authContext;
                    const token="";
                    setAuth({token});
                    const navigate = useNavigate();
                    navigate('/login');
                }
            }
        }
        return Promise.reject(error);
    });
}

export default setupAxiosInterceptors;