import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from "./context/AuthProvider";
import axiosInstance from './api/axios';
import setupAxiosInterceptors from './api/interceptors';
import MainMenu from "./MainMenu";
import Copyright from './Copyright';
import WhiteLogo from './images/WhiteLogo.js';
import BackgroundImage from './images/DATAEPattern.png';
import {SOFTDATAWSURL} from './config.js'
import MainMenuHeader from './MainMenuHeader';


const Login = () => {
    const authContext = useContext(AuthContext);
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setupAxiosInterceptors(authContext);
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosInstance.post(SOFTDATAWSURL+"/user/validateUser",
                {},
                {
                    params: {
                        username: user,
                        password: pwd
                    }
                });

            const status = response?.data?.status;
            const token = response?.data?.token;
            const profileName = response?.data?.profileName;
            const report = response?.data?.reportUser;
            const tables = response?.data?.tablesUser;
            const name = response?.data?.name;
            
            if (status === "OK") {
                setAuth({ token, user, pwd, name, profileName, report, tables });
                setSuccess(true);
            } else {
                setErrMsg('Falha na autenticação');
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Falha em aceder ao servidor');
            }
            errRef.current.focus();
        }
    }

    return (
        <>
            {success ? (
                
                <MainMenu />
                
            ) : (
                <main className='App' style={{ backgroundImage:`url(${BackgroundImage})` }}>
                    <section>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        <MainMenuHeader/>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="username">Username:</label>
                            <input
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                            />

                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                            <br/>
                            <center>
                            <button style={{width: "50%"}}>Entrar</button>
                            </center>
                        </form>
                        <br />
                        <Copyright />
                    </section>
                </main>
            )}
        </>
    )
}

export default Login