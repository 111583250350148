import React from 'react';
import TreeMenu from 'react-simple-tree-menu';
import { ListGroup } from 'react-bootstrap';
import { FaRegWindowClose } from "react-icons/fa";
import ReportTreeItem from './ReportTreeItem';

import './ReportTree.css';

class ReportTree extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            gotData: false,
        }
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);        
    }

    componentDidUpdate = async (prevProps) => {
        await this.props.treeData;
        if (typeof this.props.treeData !== 'undefined' && this.props.treeData !== prevProps.treeData) {
            const tree = this.props.treeData;
            this.setState({ treeData: tree, gotData: true, });
        }
    }

    componentDidMount = async () => {
        await this.props.treeData;
        this.setState({ treeData: this.props.treeData, gotData: true, });
    }    

    render() {
        return (

            <div style={{ padding: 5 }}>
                {this.state.gotData &&
                    <div style={{ padding: 5, color: "#515860", backgroundColor: "white" }}>
                        <TreeMenu
                            data={this.state.treeData}
                            hasSearch={false}
                            onClickItem={this.props.handler}
                            
                        >                            
                            {({ search, items, resetOpenNodes }) => (                                
                                <div>
                                    <div style={{ paddingBottom: 5 }}>
                                        
                                    <FaRegWindowClose title="Fechar tudo" onClick={resetOpenNodes} />
                                        
                                    </div>
                                    <div style={{ padding: 5, color: "#515860", backgroundColor: "white", height: "575px", overflowY: "scroll" }}>
                                        <ListGroup>
                                            {items.map(props => (
                                            <ReportTreeItem {...props}/>
                                            ))}
                                        </ListGroup>
                                    </div>

                                </div>)}

                        </TreeMenu>
      
                    </div>}

            </div>

        );
    }
}

export default ReportTree;