import React from 'react';
import BackgroundImage from '../images/DATAEPattern.png';
const TablesNoData = (props) => {
    return (
        <div align="center" style={{ backgroundColor: '#fff', color: '#000', padding: 10, width: "100%", height: "80%" , backgroundImage:`url(${BackgroundImage})`}}>
                    <h5>{props.title1}</h5>
                    <br/>
                    <br/>
                    <h3>{props.title2}</h3>
                    <br/>
                    <br/>
                    <h4>{props.title3}</h4>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
    );
}

export default TablesNoData;
